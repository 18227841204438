import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"

import user from './modules/user'
import teacher from './modules/teacher'

Vue.use(Vuex);



export default new Vuex.Store({
  modules:{
    user,
    teacher
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
        return {
        // 只储存state中的user
        user:val.user,
        teacher:val.teacher,
      }
   }
})]
});