<template>
  <div class="page_header">
    <div class="page_headerAll">
      <div class="container_box">
        <!-- <el-row :gutter="20">
          <el-col :sm="4" :xs="12">
            <img src="../assets/images/icon.png" class="icon" @click="goHome" />
          </el-col>
          <el-col :sm="16" class="header_center media_display" v-show="!commonShow">
            <ul class="header_nav">
              <li @click="goHome" :class="{'active_nav':currentPath=='/'}">首页</li>
              <li @click="goTeam" :class="{'active_nav':currentPath=='/team' || currentPath=='/detail/team_detail'}">师资团队</li>
              <li @click="goCourse" :class="{'active_nav':currentPath=='/course' || currentPath=='/detail/course_detail' || currentPath=='/detail/course_book'}">公益板块</li>
              <li @click="goLearn" :class="{'active_nav':currentPath=='/learn' || currentPath=='/detail/learn_detail'}">公益心声</li>
              <li>关于IESO</li>
              <li @click="goNewsList" :class="{'active_nav':currentPath=='/newslist' || currentPath=='/detail/news_detail'}">新闻资讯</li>
              <li @click="goNewsList" :class="{'active_nav':currentPath=='/newslist' || currentPath=='/detail/news_detail'}">隐私政策</li>
            </ul>
          </el-col>
          <el-col :sm="14" class="media_display search_common" v-show="commonShow">
            <div class="search_same">
              <i class="el-icon-search" @click="getSearch"></i>
              <input placeholder="搜索关键词" v-model="searchKey" @keyup.13="getSearch2"/>
              <i class="el-icon-close" @click="getShowserch"></i>
            </div>
          </el-col>
          <el-col :sm="4" :class="{'hideLogreg':logregPath=='/logreg'}" class="media_display login_status">
            <i class="el-icon-search search_icon_common" @click="getShowserch" v-show="!commonShow" v-if="currentPath!='/news_center_person'"></i>
            <div class="person_icon" v-if="token">
              <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter">
              <span @click="gotoNewsCenter" v-if="token&&newsNum!==0">{{newsNum}}</span>
              <div class="person_img" @mouseenter="getVisible" @mouseleave="getDisplay" style="position:relative">
                <img :src="avatar" v-if="avatar&&avatar!='undefined'">
                <img src="../assets/images/avther.jpg" v-else>
                <img src="../assets/images/member-active2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==false" style="width:18px;height:18px;position:absolute;top:60px;right:-2px;">
                <img src="../assets/images/member-no2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==true" style="width:18px;height:18px;position:absolute;top:60px;right:-2px;">
                <ul class="quit_person" v-show="seen">
                  <li @click="goPerson" v-show="accountType==2">个人中心</li>
                  <li @click="goBusiness" v-show="accountType==3">企业中心</li>
                  <li @click="toQuit">退出登录</li>
                </ul>
              </div>
            </div>
            <div class="buttonAll" v-else>
              <span class="button button1" @click="toLogin">登录</span>
              <span class="button button2" @click="toRegister">注册</span>
              <span class="last_button" @click="toTeacherLogin">|&nbsp;&nbsp;志愿者登录</span>
            </div>
          </el-col>
          <el-col :xs="12" class="media_display2 response_icon_right">
            <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter" v-if="token">
            <span v-if="token && newsNum!==0">{{newsNum}}</span>
            <i class="el-icon-menu"  @click="toOpenSmall"></i>
          </el-col>
        </el-row> -->
        <div class="index_nav_box flex-between">
          <div class="flex-start" v-show="mobileSearch">
            <div class="logo_box">
              <!-- <img src="../assets/images/icon.png" class="icon" @click="goHome" /> -->
              <img src="../assets/images/Logo.png" class="icon" @click="goHome"/>
              <!-- <span>日本語サロン</span> -->
            </div>
            <div class="header_nav_center media_display" v-show="!commonShow">
              <ul class="header_nav">
                <li @click="goHome" :class="{'active_nav':currentPath=='/'}">首页</li>
                <li @click="goTeam" :class="{'active_nav':currentPath=='/team' || currentPath=='/detail/team_detail'}">
                  师资团队
                </li>
                <li @click="goCourse"
                    :class="{'active_nav':currentPath=='/course' || currentPath=='/detail/course_detail' || currentPath=='/detail/course_book'}">
                  公益板块
                </li>
                <li @click="goLearn"
                    :class="{'active_nav':currentPath=='/learn' || currentPath=='/detail/learn_detail'}">公益心声
                </li>
                <!-- <li @click="goAbout" :class="{'active_nav':currentPath=='/about-us'}">关于IESO</li> -->
                <li @click="goNewsList"
                    :class="{'active_nav':currentPath=='/newslist' || currentPath=='/detail/news_detail'}">新闻资讯
                </li>
                <!-- <li @click="goPersonInfo" :class="{'active_nav':currentPath=='/personal-info'}">隐私政策</li> -->
              </ul>
            </div>
            <div class="media_display index_top_search" v-show="commonShow">
              <div class="search_item">
                <i class="el-icon-search" @click="getSearch2"></i>
                <input placeholder="搜索关键词" v-model="searchKey" @keyup.13="getSearch2"/>
                <i class="el-icon-close" @click="getShowSearch"></i>
              </div>
            </div>
          </div>

          <div v-show="mobileSearch">
            <div :class="{'hideLogreg':logregPath=='/logreg'}" class="media_display login_status">
              <i class="el-icon-search search_icon_common" @click="getShowSearch" v-show="!commonShow"
                 v-if="currentPath!='/news_center_person'&&currentPath!='/personal-info'&&currentPath!='/about-us'"></i>
              <div class="person_icon" v-if="token">
                <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter">
                <span @click="gotoNewsCenter" v-if="token&&newsNum!==0">{{ newsNum }}</span>
                <div class="person_img" @mouseenter="getVisible" @mouseleave="getDisplay" style="position:relative">
                  <img :src="avatar">
                  <!--                    <img src="../assets/images/avther.jpg" v-else>-->
                  <!-- <img src="../assets/images/member-active2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==false" style="width:18px;height:18px;position:absolute;top:60px;right:-2px;">
                  <img src="../assets/images/member-no2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==true" style="width:18px;height:18px;position:absolute;top:60px;right:-2px;"> -->
                  <ul class="quit_person" v-show="seen">
                    <li @click="goPerson" v-show="accountType==2">个人中心</li>
                    <li @click="goBusiness" v-show="accountType==3">企业中心</li>
                    <li @click="toQuit">退出</li>
                  </ul>
                </div>
              </div>
              <div class="buttonAll" v-else>
                <span class="button button2" @click="toLogin">中小学生登录</span>
                <span class="button button2" @click="toRegister">中小学生注册</span>
                <!-- <span class="last_button" @click="toTeacherLogin">|&nbsp;&nbsp;&nbsp;&nbsp;志愿者登录</span> -->
                |&nbsp;&nbsp;<span class="button button1" @click="toTeacherLogin">志愿者登录</span>
                <span class="button button1" :class="{'button-disabled':!enableTeacherRegister}"
                      @click="toTeacherRegister">志愿者注册</span>
              </div>
            </div>
            <div class="media_display2 response_icon_right">
              <i class="el-icon-search" @click="mobileSearch=0"></i>
              <img src="../assets/images/news.png" class="person_new" @click="gotoNewsCenter" v-if="token">
              <span v-if="token && newsNum!==0">{{ newsNum }}</span>
              <i class="el-icon-menu" @click="toOpenSmall"></i>
            </div>
          </div>

          <div class="mobile_search_box" v-show="!mobileSearch">
            <i class="el-icon-arrow-left" @click="mobileSearch=1;searchKey='';getSearch2()"></i>
            <input type="text" placeholder="搜索关键词" v-model="searchKey" @keyup.13="getSearch2">
            <el-button size="mini" type="danger" @click="getSearch2">搜索</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="response_bg media_display2" v-if="isShow">
      <div class="close" @click="getClose">
        <i class="el-icon-close"></i>
      </div>
      <ul>
        <li @click="goHome" :class="{'active_nav':currentPath=='/'}">首页</li>
        <li @click="goTeam" :class="{'active_nav':currentPath=='/team' || currentPath=='/detail/team_detail'}">
          师资团队
        </li>
        <li @click="goCourse"
            :class="{'active_nav':currentPath=='/course' || currentPath=='/detail/course_detail' || currentPath=='/detail/course_book'}">
          公益板块
        </li>
        <li @click="goLearn" :class="{'active_nav':currentPath=='/learn' || currentPath=='/detail/learn_detail'}">
          公益心声
        </li>
        <!-- <li @click="goAbout" :class="{'active_nav':currentPath=='/about-us'}">关于IESO</li> -->
        <li @click="goNewsList" :class="{'active_nav':currentPath=='/newslist' || currentPath=='/detail/news_detail'}">
          新闻资讯
        </li>
        <!-- <li @click="goPersonInfo" :class="{'active_nav':currentPath=='/personal-info'}">隐私政策</li> -->
      </ul>
      <div>
        <div class="person_icon2" v-if="token" style="position:relative">
          <!-- <img :src="avatar" v-if="avatar&&avatar!='undefined'" class="person_img" @click="goPerson">
          <img src="../assets/images/avther.jpg" class="person_img" v-else @click="goPerson"> -->
          <div :style="{backgroundImage:'url('+avatar+')'}" class="person_img" @click="goPerson"></div>
          <!--            <div  class="person_img" v-else @click="goPerson"></div>-->
          <!-- <img src="../assets/images/member-active2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==false" style="width:14px;height:14px;position:absolute;top:38px;left:54%;">
          <img src="../assets/images/member-no2.png" alt="" v-if="groupId&&groupId!=='undefined'&&overdue==true" style="width:14px;height:14px;position:absolute;top:38px;left:54%;"> -->
          <div class="button" @click="toQuit">退出</div>
        </div>
        <div class="buttonAll2" v-else>
          <div class="button_login" @click="toLogin">中小学生登录</div>
          <div class="button_login" @click="toRegister">中小学生注册</div>
          <div class="button_register" @click="toTeacherLogin">志愿者登录</div>
          <div class="button_register" :class="{'button-disabled':!enableTeacherRegister}" @click="toTeacherRegister">
            志愿者注册
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'

export default {
  data() {
    return {
      suspIsShow: false,
      navList: [
        {
          title: '首页'
        },
        {
          title: '师资团队'
        },
        {
          title: '公益板块'
        },
        {
          title: '公益心声'
        },
        {
          title: '关于我们'
        }
      ],
      // teaToken: '',
      defalutIndex: 0,
      btnShow: false,
      searchKey: '',
      isShow: false, //小屏幕路由显示
      seen: false,
      commonShow: false,
      teacherType: 0,
      // avatar: '',
      ws: null,
      newsNum: 0,
      // token:'',
      // accountType:'',
      groupId: '', //有groupId说明已经是会员 否则不是
      overdue: false,//会员是否过期
      mobileSearch: 1, //移动端搜索框
    }
  },
  methods: {
    gotoNewsCenter() {
      this.$router.push('/news_center_person')
    },
    //个人中心显示隐藏
    getVisible() {
      this.seen = !this.seen
    },
    getDisplay() {
      this.seen = !this.seen
    },
    //搜索框的显示
    getShowSearch() {
      this.commonShow = !this.commonShow
      this.searchKey = '';
      this.getSearch2();
    },
    //首页
    goHome() {
      this.$router.push({path: '/'})
      this.isShow = false
    },
    //师资团队
    goTeam() {
      this.$router.push({path: '/team'})
      this.isShow = false
    },
    //公益板块
    goCourse() {
      this.$router.push({path: '/course'})
      this.isShow = false
    },
    //公益心声
    goLearn() {
      this.$router.push({path: '/learn'})
      this.isShow = false
    },
    goNewsList() {
      this.$router.push({path: '/newslist'})
      this.isShow = false
    },
    //关于我们
    goAbout() {
      this.$router.push({path: '/about-us'})
      this.isShow = false
    },
    //个人信息保护方针
    goPersonInfo() {
      this.$router.push({path: '/personal-info'})
      this.isShow = false
    },
    //登录页
    toLogin() {
      this.isShow = false
      this.$router.push({path: '/logreg/login'})
    },
    //注册页
    toRegister() {
      this.isShow = false
      this.$router.push({path: '/logreg/register'})
    },
    //个人主页
    goPerson() {
      if (window.screen.width <= 767) {
        this.isShow = !this.isShow
      }
      this.$router.push({
        path: '/personal/person_book',
        query: {studentId: this.studentId}
      })
    },
    //企业中心
    goBusiness() {
      this.$router.push({
        path: '/business_center/business_home',
        query: {companyId: this.companyId}
      })
    },
    //搜索
    getSearch() {
      // this.$store.dispatch('user/saveSearch',this.searchKey);
      // console.log(this.$route.matched[0].instances.default);
      // console.log(this)
      this.$route.matched[0].instances.default.postData1.page = 1
      this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
      this.$route.matched[0].instances.default.getData()
    },
    // getSearch2() {
    //   if (
    //     this.$route.path == '/' ||
    //     this.$route.path.substring(0, 7) == '/detail'
    //   ) {
    //     this.$router.push({ path: '/course' })
    //     this.searchKey = ''
    //   }
    // },
    getSearch2() {
      if (
          this.$route.path == '/' ||
          this.$route.path.substring(0, 7) == '/detail'
      ) {
        this.$router.push({path: '/course'})
        // this.searchKey = ''
      } else {
        // console.log(this)
        this.$route.matched[0].instances.default.postData1.page = 1
        this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
        this.$route.matched[0].instances.default.getData()
        // this.searchKey = ''
      }
    },
    // getSearch2(){
    //   if(this.$route.path == '/' ||
    //     this.$route.path.substring(0, 7) == '/detail'
    //     ){
    //       if(this.searchKey==''){
    //         this.$router.push({ path: '/course' })
    //       }else{
    //         console.log(this)
    //         this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
    //         this.$route.matched[0].instances.default.getData()
    //       }
    //     }else{
    //       this.$route.matched[0].instances.default.postData1.searchKey = this.searchKey
    //       this.$route.matched[0].instances.default.getData()
    //     }
    // },
    //退出登录
    toQuit() {
      this.$confirm('确定要退出登录？', '确认', {
        confirmButtonText: '退出',
        cancelButtonText: '取消'
      })
          .then(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('studentId')
            localStorage.removeItem('accountId')
            localStorage.removeItem('studentGroupId')
            localStorage.removeItem('area')
            localStorage.removeItem('startDate')
            localStorage.removeItem('endDate')
            this.$store.dispatch('user/saveUser', '')
            this.$store.dispatch('teacher/saveUser', '')
            if (
                this.$route.path.substring(0, 5) == '/team' ||
                this.$route.path.substring(0, 7) == '/course' ||
                this.$route.path.substring(0, 6) == '/learn'
            ) {
              this.$router.go(0)
            } else {
              this.$router.push({path: '/'})
            }
            this.$message({
              type: 'success',
              message: '退出成功'
            })
          })
          .catch(() => {
          })
    },
    //志愿者登录
    toTeacherLogin() {
      console.log('123', this.teacherToken)
      if (this.teacherToken) {
        this.$router.push({path: '/teacher_center/teacher_book'})
      } else {
        this.$router.push({path: '/teacher_center/teacher_login'})
      }
      this.isShow = false
    },
    toTeacherRegister() {
      if (this.enableTeacherRegister) {
        this.isShow = false
        this.$router.push({path: '/logreg/register_teacher'})
      } else {
        this.$message({
          message: '志愿者注册通道已关闭，请等待下次开启通知',
          type: 'warning'
        })
      }
    },
    //教师退出
    getQuitTeacher() {
      localStorage.removeItem('teacherType')
      this.$router.push({path: '/'})
      this.$router.go(0)
    },
    ...mapMutations({
      saveUser: 'user/saveUser'
    }),
    //打开小屏幕  路由切换
    toOpenSmall() {
      this.isShow = !this.isShow
      console.log(this.isShow, '测试小屏幕')
    },
    //关闭小屏幕
    getClose() {
      this.isShow = !this.isShow
    },
    connectWs() {
      if ("WebSocket" in window) {
        let wsUrl = this.wsUrl
        let accountId = localStorage.getItem('accountId')
        if (accountId && accountId !== 'undefined') {
          // 打开一个 web socket
          this.ws = new WebSocket(`${wsUrl}/ws/not-read-msg-num/${accountId}`);
          this.ws.onopen = function () {
            // Web Socket 已连接上，使用 send() 方法发送数据
            // ws.send("发送数据");
            console.log('连接成功')
          };
          var that = this
          this.ws.onmessage = function (evt) {
            var received_msg = evt.data;
            var jsonObject = JSON.parse(received_msg)
            that.newsNum = jsonObject.data
            // console.log(that.newsNum)
          };
          this.ws.onclose = function () {
            // 关闭 websocket
            console.log("连接已关闭...");
          };
        }
      } else {
        // 浏览器不支持 WebSocket
        alert("您的浏览器不支持 WebSocket!");
      }
    },
    getNews() {
      var accountId = localStorage.getItem('accountId')
      if (accountId && accountId !== 'undefined') {
        this.$axios.get('/message/num/' + accountId).then(res => {
          this.newsNum = res.data.data
        })
      }
    },
  },
  computed: {
    currentPath() {
      return this.$route.path
    },
    logregPath() {
      return this.$route.path.substring(0, 7)
    },
    ...mapState({
      token: state => state.user.token,
      accountType: state => state.user.accountType,
      studentId: state => state.user.studentId,
      companyId: state => state.user.companyId,
      // avatar: state => state.user.avatar,
      teacherToken: state => state.teacher.token,
    }),
    ...mapGetters({
      avatar: 'user/avatar'
    })
  },
  watch: {
    $route(to, from) {
      this.groupId = localStorage.getItem('studentGroupId')
      var endtime = localStorage.getItem('endDate')
      var stringTime = Date.parse(new Date(endtime))
      var nowtime = new Date().getTime()
      if (nowtime > stringTime) { //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
        this.overdue = true
      } else {
        this.overdue = false
      }
      // console.log(this.groupId,stringTime,nowtime,this.overdue,'stringTime')
    }
  },
  mounted() {
    this.groupId = localStorage.getItem('studentGroupId')
    var endtime = localStorage.getItem('endDate')
    var stringTime = Date.parse(new Date(endtime))
    var nowtime = new Date().getTime()
    if (nowtime > stringTime) { //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
      this.overdue = true
    } else {
      this.overdue = false
    }

    this.$bus.on('login', () => {
      this.getNews()
      if (!this.ws) {
        this.connectWs()
      }
    })
    this.connectWs()
    this.getNews()

    // this.avatar = localStorage.getItem('avatar')
    // this.$bus.on('changeAvatar', avatar => {
    //   this.avatar = avatar
    // })
    this.$bus.on('changeMemberLogo', member => {
      this.groupId = member.groupId
      var endtime = member.endDate
      var stringTime = Date.parse(new Date(endtime))
      var nowtime = new Date().getTime()
      if (nowtime > stringTime) { //如果当前时间的时间戳大于会员截止时间的时间戳 则会员过期
        this.overdue = true
      } else {
        this.overdue = false
      }
    })
    // if(localStorage.getItem('teacherType')){
    //   this.teacherType = localStorage.getItem('teacherType')
    // }
  },
  created() {
  },
}
</script>

<style>
.index_nav_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100px;

  .logo_box {
    width: 154px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .header_nav_center {
    padding: 0 10px;
    margin-left: 20px;
    //margin-right: 28px;

    .header_nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        font-size: 16px;
        padding: 0 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .index_top_search {
    width: 792px;
    margin-left: 40px;
    height: 70px;
    line-height: 70px;
    color: #F06A69;

    .search_item {
      input {
        height: 30px;
        width: 90%;
        text-indent: 10px;
        border: none;
        border-bottom: 1px solid #F06A69;
      }

      i {
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  .login_status {
    //width: 250px;
    float: right;

    .person_new {
      cursor: pointer;
    }

    .search_icon_common {
      cursor: pointer;
    }

    .buttonAll {
      //width: 250px;
      font-size: 12px;

      .button {
        width: auto;
        padding: 2px 5px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .index_nav_box {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .response_bg {
    height: 600px;
    overflow-y: auto;

    ul {
      width: 150px;

      li {
        width: 150px;
      }
    }
  }

}

.button-disabled {
  background: #f2a9a9 !important;
  cursor: not-allowed !important;
}
</style>
