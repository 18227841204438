import promise from 'es6-promise'
import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/api'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VClamp from 'vue-clamp'
import './assets/css/commen.less'
import bus from './utils/eventBus'
import moment from 'moment' //JavaScript 日期处理类库
import { handleAvatar } from './utils'

promise.polyfill() // 兼容 Promise
moment.locale('zh-cn')

Vue.config.productionTip = false
Vue.use(api)
Vue.use(ElementUI)
Vue.component('VClamp', VClamp)
Vue.use(bus)
Vue.prototype.$moment = moment

Vue.prototype.handleAvatar = handleAvatar
// 是否开启老师注册
Vue.prototype.enableTeacherRegister = true


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
