import {getDefaultAvatar, handleAvatar} from '../../utils'

const state = {
  token: '',
  accountId: '',
  accountType: '',//登录类型
  studentId: '',
  username: '',
  companyId: '',
  avatar: '',
  realName: '',
  studentGroupId:'',
  // searchKey:''
  startDate:'',//会员开始时间
  endDate:'',//会员结束时间
  area:'',//该用户在中国还是在日本 1中国2日本
  isMobile:false,
}

const getters = {
  isMobile(state) {
    return state.isMobile
  },
  avatar(state){
    return state.avatar
  },
  userInfo(state) {
    return state.userInfo
  }
}

const mutations = {
  save_user(state, info) {
    console.log(info,'info')
    state.accountId = info.id
    // localStorage.setItem('accountId', info.id)
    localStorage.setItem('accountId', state.accountId)
    state.token = info.token;
    state.username = info.username;
    localStorage.setItem('username', state.username)
    state.area = info.area
    localStorage.setItem('area',state.area)
    state.accountType = info.accountType;
    localStorage.setItem('accountType', info.accountType)
    state.avatar = info.avatar ?? getDefaultAvatar(info.student && info.student.gender, false);
    localStorage.setItem('avatar', state.avatar)
    localStorage.setItem('phoneNumber',info.phoneNo)
    if (state.token) {
      localStorage.setItem('token', state.token)
    }
    if (info.student) {
      state.userInfo = info.student
      // state.accountId = info.student.accountId;
      state.studentId = info.student.id;
      state.realName = info.student.name;
      localStorage.setItem('studentId', state.studentId)
      localStorage.setItem('realName', state.realName)
      state.startDate = info.student.startDate
      state.endDate = info.student.endDate
      localStorage.setItem('startDate', state.startDate)
      localStorage.setItem('endDate', state.endDate)
      
      state.studentGroupId = info.student.groupId
      localStorage.setItem('studentGroupId',state.studentGroupId)
      state.studentArea = info.student.stuArea
      localStorage.setItem('studentArea',state.studentArea)
    }
    if (info.enterprise) {
      state.accountId = info.enterprise.accountId;
      state.companyId = info.enterprise.id;
      state.realName = info.enterprise.name;
      state.phoneNo = info.enterprise.phoneNo;
      localStorage.setItem('companyId', state.companyId)
    }
    localStorage.setItem('realName', state.realName)
  },
  // save_search(state,info){
  //   state.searchKey= info.searchKey
  // }
    save_ismobile(state,data){
      state.isMobile = data
    },
    save_avatar(state, data){
      state.avatar = data;
    }
}

const actions = {
  saveUser({ commit }, params) {
    commit('save_user', params)
  },
  saveIsMobile(context,data){
    context.commit('save_ismobile',data);
  },
  saveAvatar({ commit }, params) {
    commit('save_avatar', params)
  },
}

export default {
  namespaced:true,//用于在全局引用此文件里的方法时标识这一个的文件名
  state,
  getters,
  mutations,
  actions
}