import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index'),
  },
  {
    path: '/logreg/login',
    name: 'login',
    component: () => import('../views/logreg/login'),
  },
  {
    path: '/logreg/register',
    name: 'register',
    component: () => import('../views/logreg/register'),
  },
  {
    path: '/logreg/register_teacher',
    name: 'register_teacher',
    component: () => import('../views/logreg/register_teacher'),
  },
  {
    path: '/logreg/forget_pwd',
    name: 'forgetPwd',
    component: () => import('../views/logreg/forget_pwd'),
  },
  {
    path: '/logreg/agreement',
    name: 'agreement',
    component: () => import('../views/logreg/agreement'),
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/course'),
    meta:{
      keepAlive:true,
      deepth:1
    }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/team'),
    meta:{
      keepAlive:true,
      deepth:1
    }
  },
  {
    path: '/learn',
    name: 'learn',
    component: () => import('../views/learn'),
    meta:{
      keepAlive:true,
      deepth:1
    }
  },
  {
    path: '/detail/course_detail',
    name: 'courseDetail',
    component: () => import('../views/detail/course_detail'),
    meta:{
      deepth:2
    }
  },
  {
    path: '/detail/team_detail',
    name: 'teamDetail',
    component: () => import('../views/detail/team_detail'),
    meta:{
      deepth:2
    }
  },
  {
    path: '/detail/learn_detail',
    name: 'learnDetail',
    component: () => import('../views/detail/learn_detail'),
    meta:{
      deepth:2
    }
  },
  {
    path: '/detail/news_detail',
    name: 'newsDetail',
    component: () => import('../views/detail/news_detail'),
    meta:{
      deepth:2
    }
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('../views/about-us'),
  },
  {
    path: '/newslist',
    name: 'newslist',
    component: () => import('../views/newslist'),
    meta:{
      keepAlive:true,
      deepth:1
    }
  },
  {
    path: '/personal-info',
    name: 'personalInfo',
    component: () => import('../views/personal-info'),
  },
  {
    path: '/detail/course_book',
    name: 'courseBook',
    component: () => import('../views/detail/course_book'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/person_home',
    name: 'personHome',
    component: () => import('../views/personal/person_home'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/person_book',
    name: 'personBook',
    component: () => import('../views/personal/person_book'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/person_collect',
    name: 'personCollect',
    component: () => import('../views/personal/person_collect'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/person_member',
    name: 'personMember',
    component: () => import('../views/personal/person_member'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/follow_teacher',
    name: 'followTeacher',
    component: () => import('../views/personal/follow_teacher'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/personal/student_lesson_count',
    name: 'studentLessonCount',
    component: () => import('../views/personal/student_lesson_count'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/edit_info',
    name: 'editInfo',
    component: () => import('../views/edit_info'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/news_center_person',
    name: 'newsCenterPerson',
    component: () => import('../views/news_center_person'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_login',
    name: 'teacherLogin',
    component: () => import('../views/teacher_center/teacher_login'),
  },
  {
    path: '/teacher_center/forget_pwd',
    name: 'forgetPwd',
    component: () => import('../views/teacher_center/forget_pwd'),
  },
  {
    path: '/teacher_center/teacher_book',
    name: 'teacherBook',
    component: () => import('../views/teacher_center/teacher_book'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_lesson_count',
    name: 'teacherLessonCount',
    component: () => import('../views/teacher_center/teacher_lesson_count'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_cal',
    name: 'teacherCal',
    component: () => import('../views/teacher_center/teacher_cal'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_course',
    name: 'teacherCourse',
    component: () => import('../views/teacher_center/teacher_course'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_student',
    name: 'teacherStudent',
    component: () => import('../views/teacher_center/teacher_student'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_settime',
    name: 'teacherSettime',
    component: () => import('../views/teacher_center/teacher_settime'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/course_detail',
    name: 'teacherCourseDetail',
    component: () => import('../views/teacher_center/course_detail'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/teacher_center/teacher_info',
    name: 'teacherInfo',
    component: () => import('../views/teacher_center/teacher_info'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/news_center_teacher',
    name: 'newsCenterTeacher',
    component: () => import('../views/news_center_teacher'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/business_center/business_home',
    name: 'businessHome',
    component: () => import('../views/business_center/business_home'),
  },
  {
    path: '/business_center/business_student',
    name: 'businessStudent',
    component: () => import('../views/business_center/business_student'),
  },
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
    if (token) { // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      Message.warning('请先登录')
      next({
        path: '/',
      })
    }
  }else{
    next()
  }
})

export default router
