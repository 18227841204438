import Vue from 'vue'

export const getDefaultAvatar = (gender = 1, isTeacher = true) => {
    const pictures = isTeacher ? [ 'tm.jpg', 'tw.jpg' ] : [ 'sm.jpg', 'sw.jpg' ]
    const path = gender === 1 ? pictures[0] : pictures[1]
    return Vue.prototype.$axios.root_path + '/imgs/' + path
}
export const handleAvatar = (item = {}, isTeacher = true) => {
    const img = new Image()
    img.src = item.avatar
    img.onerror = () => {
        item.avatar = getDefaultAvatar(item.gender, isTeacher)
    }
}
